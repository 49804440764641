import { CircularProgress } from '@material-ui/core'
import React, { lazy } from 'react'
import { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ConnectionError from './components/ConnectionError'

const MainContainer = lazy(() => import('./components/Main'))
const AuthContainer = lazy(() => import('./components/Auth'))
const AdminContainer = lazy(() => import('./components/Admin'))
const VerifyContainer = lazy(() => import('./components/Verify'))
const ReportContainer = lazy(() => import('./components/Report'))

function LoadingFallback() {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <CircularProgress />
    </div>
  )
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/verify">
          <Suspense fallback={<LoadingFallback />}>
            <VerifyContainer />
          </Suspense>
        </Route>
        <Route path="/error" component={ConnectionError} />
        <Route path="/admin">
          <Suspense fallback={<LoadingFallback />}>
            <AdminContainer />
          </Suspense>
        </Route>
        <Route path="/auth">
          <Suspense fallback={<LoadingFallback />}>
            <AuthContainer />
          </Suspense>
        </Route>
        <Route path="/report">
          <Suspense fallback={<LoadingFallback />}>
            <ReportContainer />
          </Suspense>
        </Route>
        <Route path="/" exact={true}>
          <Suspense fallback={<LoadingFallback />}>
            <MainContainer />
          </Suspense>
        </Route>
      </Switch>
    </Router>
  )
}

export default App
