const defaultState = {
  token: '',
  endpoint: `${window.location.protocol}//${window.location.host}/api`
}

export const Action = {
  SET_TOKEN: 0,
  REMOVE_TOKEN: 1
}

export const mapStateToProps = state => {
  return {
    token: state.token,
    endpoint: state.endpoint
  }
}

export const mapDispatchToProps = (dispatch, state) => {
  return {
    setToken: token => dispatch({type: Action.SET_TOKEN, payload: token}),
    removeToken: () => dispatch({type: Action.REMOVE_TOKEN})
  }
}

function Storage(state = defaultState, action) {
  switch (action.type) {
    case Action.SET_TOKEN: {
      sessionStorage.setItem('token', action.payload)
      
      state = {
        ...state,
        token: action.payload
      }

      return state
    }

    case Action.REMOVE_TOKEN: {
      sessionStorage.setItem('token', '')
      
      state = {
        ...state,
        token: ''
      }
      
      return state
    }

    default: {
      return state
    }
  }
}

export default Storage