import React from 'react'
import { Button, Container, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

function ConnectionError() {
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <Container maxWidth="sm">
      <Grid container justify="center">
        <Typography align="center" variant="h3">Maaf, terjadi kesalahan jaringan!</Typography>
        <Button variant="outlined" color="primary" onClick={goBack}>
          Coba lagi...
        </Button>
      </Grid>
    </Container>
  )
}

export default ConnectionError